<template>
  <el-drawer :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
    :modal-append-to-body="false">
    <div class="h-full flex flex-dc">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">{{propTitle}}道具详情</div>
        <i class="el-icon-close fs-20" @click="handleClose"></i>
      </div>
      <div class="flex-1 overflow-scroll-y">
        <t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="4" @handleEvent="handleEvent"
          class="drawer_form">
          <!-- 上传图片 -->
          <template #uploadFile="even">
            <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
              iconName="el-icon-upload" iconSize="26" :fileNumber="even.data.fileNumber"
              v-model="formOpts.formData[even.value]"></upload-file>
          </template>
        </t-simple-form>
      </div>
      <div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
        <el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
        <el-button type="primary" plain @click="handleClose">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'activityPropDetails',
  components: {
    "TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  data () {
    return {
      propTitle: '',//弹框标题
      goodId: '',//详情id
      isDrawer: false,
      butLoading: false,
      fieldTemplateList: [
        { label: '名称', value: 'exchangeName', comp: 'el-input' },
        { label: '类型', value: 'propType', type: 'select-arr', comp: 'el-select', list: 'propTypes', arrLabel: 'key', arrKey: 'value', event: 'showCoupons' },
        // { label: '兑换类型', value: 'exchangeType', type: 'select-arr', comp: 'el-select', list: 'exchangeTypes', arrLabel: 'key', arrKey: 'value' },
        { label: '库存量', value: 'inventory', type: 'number', comp: 'el-input' },
        { label: '兑换数量', value: 'exchangeNum', type: 'number', comp: 'el-input' },
        { label: '兑换次数', value: 'exNum', type: 'number', comp: 'el-input' },
        { label: '兑换价格', value: 'price', type: 'number', comp: 'el-input', },
        { label: '排序', value: 'sort', type: 'number', comp: 'el-input' },
        { label: '图片', value: 'img', slotName: 'uploadFile', fileNumber: 1, className: 't-form-block' },
      ],
      formOpts: {
        labelPosition: 'top',
        ref: null,
        formData: {
          exchangeName: '',//名称
          propType: '',//类型
          price: '',//兑换价格
          exchangeType: '',//兑换类型
          inventory: '', //库存
          exchangeNum: 0, //兑换数量
		  exNum:0,//兑换次数
          sort: '',  //排序
          img: '',  //图片
          money: '',//面额
          doorSill: '',//门槛
          couponsType: '',//优惠类型
        },
        fieldList: this.fieldTemplateList,
        rules: {
          exchangeName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          propType: [{ required: true, message: '请至少选择一个类型', trigger: 'change' }],
          price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
          exchangeType: [{ required: true, message: '请至少选择一个兑换类型', trigger: 'change' }],
          soldPrice: [{ required: true, message: '请输入售出价格', trigger: 'blur' }],
          inventory: [{ required: true, message: '请输入库存量', trigger: 'blur' }],
          exchangeNum: [{ required: true, message: '请输入兑换数量', trigger: 'blur' }],
          money: [{ required: true, message: '请输入面额', trigger: 'blur' }],
          doorSill: [{ required: true, message: '请输入门槛', trigger: 'blur' }],
          couponsType: [{ required: true, message: '请输入优惠类型', trigger: 'blur' }],
          // sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        },
        //按钮
        operatorList: [],
        // 相关列表
        listTypeInfo: {
          propTypes: this.$store.state.menu.dictList.prop_type, //道具类型
          exchangeTypes: this.$store.state.menu.dictList.exchange_type, //兑换类型 1积分 2卡片
          couponsTypes: this.$store.state.menu.dictList.coupons_type, //优惠劵类型
        }
      },
    }
  },
  mounted () {
    this.formOpts.fieldList = this.fieldTemplateList
		
  },
  methods: {
    // 初始化
    init (id, sort, type) {
      this.isDrawer = true
      if (id) {
        this.getDetails(id)
      } else {
        this.formOpts.formData.sort = sort
        this.formOpts.formData.exchangeType = type
        if (type == 1) {
          this.propTitle = '积分'
        } else if (type == 2) {
          this.propTitle = '卡片'
        }
      }
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      this.resetForm()
    },
    //获取详情数据
    getDetails (id) {
      this.$http.get(`/exchange/findExchangeById?id=${id}`).then(({ data: res }) => {
        let goodData = res.data
        this.formOpts.formData = goodData
      })
    },
    // 触发事件
    handleEvent (type, val) {
      switch (type) {
        case 'showCoupons':
          if (val == 4) {
            let arr = [
              { label: '面额', value: 'money', type: 'number', comp: 'el-input' },
              { label: '门槛', value: 'doorSill', type: 'number', comp: 'el-input' },
              { label: '优惠类型', value: 'couponsType', type: 'select-arr', comp: 'el-select', list: 'couponsTypes', arrLabel: 'key', arrKey: 'value' }
            ]
            this.formOpts.fieldList = this.fieldTemplateList.concat(arr)
          } else {
            this.formOpts.fieldList = this.fieldTemplateList
          }
          break
      }
    },
    // 提交form表单
    submitForm () {
      this.formOpts.ref.validate((valid) => {
        if (valid) {
          this.butLoading = true
          let goodData = JSON.parse(JSON.stringify(this.formOpts.formData))
          let apiUrl = ''
          if (goodData.id) { 
						//修改
            apiUrl = '/exchange/editExchange'
          } else {   //新增
            apiUrl = '/exchange/addExchange'
          }
          if (goodData.propType != 4) {
            goodData.money = ''
            goodData.doorSill = ''
            goodData.couponsType = ''
          }
          this.$http.post(apiUrl, goodData).then(res => {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.butLoading = false
            this.$emit('refresh')
            this.handleClose()
          }).catch(err => {
            this.butLoading = false
          })
        }
      })
    },
	// 重置form表单
	resetForm() {
		Object.assign(this.$data.formOpts, this.$options.data.call(this).formOpts)
		this.$nextTick(() => {
			this.formOpts.ref.clearValidate()
		})
	},
  }
}
</script>

<style lang="less" scoped>
</style>